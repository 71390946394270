import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { spaQueries } from '@qb/httpRequest/spaQueries';

type MutationOptions = {
  tenantAlias: string;
  onSuccess?: () => void;
};

export const usePostForgotPasswordRequest = ({
  tenantAlias,
  onSuccess,
}: MutationOptions) => {
  return useMutation({
    ...spaQueries.auth.forgotPassword(tenantAlias),
    onSuccess: () => {
      toast.success('Sent a password reset email to your email');
      if (onSuccess) {
        onSuccess();
      }
    },
    onError: () => {
      toast.error('Unable to send password reset email.');
    },
  });
};
